var hostname = window.location.hostname

var EMAIL_URL = 'http://email-api.localhost/emails'

switch (hostname) {
    case 'organogenesis.com':
    case 'www.organogenesis.com':
        EMAIL_URL =    'https://email-api.prod.ogn-v2.colddata.com/emails'
        break
    case 'organogenesis-com.stg.ogn.colddata.com':
        EMAIL_URL = 'https://email-api.stg.ogn-v2.colddata.com/emails'
        break
}

var CONTACT_FORM_TITLES = {
    dr:'Dr.',
    mr:'Mr.',
    mrs:'Mrs.',
    ms:'Ms.',
    mx:'Mx.'
};
var CONTACT_FORM_REASONS = {
    'compliance-inquiries': 'Compliance Inquiries',
    'corporate-inquiries': 'Corporate Inquiries',
    'investor-inquiries': 'Investor Inquiries',
    'press-inquiries': 'Press Inquiries',
    'patient-inquiries': 'Patient Inquiries',
    'medical-inquiries': 'Medical Inquiries',
    'regulatory-hotline': 'Regulatory Hotline',
    'reimbursement-inquiries': 'Reimbursement Inquiries',
    'customer-support': 'Customer Support',
};
var CONTACT_FORM_REASON_TO_SENDER_ID = {
    'compliance-inquiries': 'ognGeneralContactComplianceInquiries',
    'corporate-inquiries': 'ognGeneralContactCorporateInquiries',
    'investor-inquiries': 'ognGeneralContactInvestorInquiries',
    'press-inquiries': 'ognGeneralContactPressInquiries',
    'patient-inquiries': 'ognGeneralContactPatientInquiries',
    'medical-inquiries': 'ognGeneralContactMedicalInquiries',
    'regulatory-hotline': 'ognGeneralContactRegulatoryHotline',
    'reimbursement-inquiries': 'ognGeneralContactReimbursementInquiries',
    'customer-support': 'ognGeneralContactCustomerSupport',
};
var CONTACT_FORM_SPECIALTIES = {
    'dermatologist': 'Dermatologist',
    'podiatrist': 'Podiatrist',
    'plastic-surgeon': 'Plastic Surgeon',
    'general-surgeon': 'General Surgeon',
    'vascular-surgeon': 'Vascular Surgeon',
    'gerontologist': 'Gerontologist',
    'primary-care-physician': 'Primary Care Physician',
    'internal-medicine': 'Internal Medicine',
    'nurse': 'Nurse',
    'hospitalist': 'Hospitalist'
};
var CONTACT_FORM_COUNTRIES = {
    'US': 'United States',
    'AF': 'Afghanistan',
    'AX': 'Åland Islands',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'American Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua and Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaijan',
    'BS': 'Bahamas',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgium',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia, Plurinational State of',
    'BQ': 'Bonaire, Sint Eustatius and Saba',
    'BA': 'Bosnia and Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet Island',
    'BR': 'Brazil',
    'IO': 'British Indian Ocean Territory',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'KH': 'Cambodia',
    'CM': 'Cameroon',
    'CA': 'Canada',
    'CV': 'Cape Verde',
    'KY': 'Cayman Islands',
    'CF': 'Central African Republic',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands',
    'CO': 'Colombia',
    'KM': 'Comoros',
    'CG': 'Congo',
    'CD': 'Congo, the Democratic Republic of the',
    'CK': 'Cook Islands',
    'CR': 'Costa Rica',
    'CI': 'Côte d\'Ivoire',
    'HR': 'Croatia',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'EC': 'Ecuador',
    'EG': 'Egypt',
    'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'ET': 'Ethiopia',
    'FK': 'Falkland Islands (Malvinas)',
    'FO': 'Faroe Islands',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'France',
    'GF': 'French Guiana',
    'PF': 'French Polynesia',
    'TF': 'French Southern Territories',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germany',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Greece',
    'GL': 'Greenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard Island and McDonald Islands',
    'VA': 'Holy See (Vatican City State)',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Iran, Islamic Republic of',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IM': 'Isle of Man',
    'IL': 'Israel',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KP': 'Korea, Democratic People\'s Republic of',
    'KR': 'Korea, Republic of',
    'KW': 'Kuwait',
    'KG': 'Kyrgyzstan',
    'LA': 'Lao People\'s Democratic Republic',
    'LV': 'Latvia',
    'LB': 'Lebanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libya',
    'LI': 'Liechtenstein',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MK': 'Macedonia, the former Yugoslav Republic of',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshall Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesia, Federated States of',
    'MD': 'Moldova, Republic of',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Morocco',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Netherlands',
    'NC': 'New Caledonia',
    'NZ': 'New Zealand',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk Island',
    'MP': 'Northern Mariana Islands',
    'NO': 'Norway',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestinian Territory, Occupied',
    'PA': 'Panama',
    'PG': 'Papua New Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippines',
    'PN': 'Pitcairn',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'RE': 'Réunion',
    'RO': 'Romania',
    'RU': 'Russian Federation',
    'RW': 'Rwanda',
    'BL': 'Saint Barthélemy',
    'SH': 'Saint Helena, Ascension and Tristan da Cunha',
    'KN': 'Saint Kitts and Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint Martin (French part)',
    'PM': 'Saint Pierre and Miquelon',
    'VC': 'Saint Vincent and the Grenadines',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome and Principe',
    'SA': 'Saudi Arabia',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SX': 'Sint Maarten (Dutch part)',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'SB': 'Solomon Islands',
    'SO': 'Somalia',
    'ZA': 'South Africa',
    'GS': 'South Georgia and the South Sandwich Islands',
    'SS': 'South Sudan',
    'ES': 'Spain',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard and Jan Mayen',
    'SZ': 'Swaziland',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'SY': 'Syrian Arab Republic',
    'TW': 'Taiwan, Province of China',
    'TJ': 'Tajikistan',
    'TZ': 'Tanzania, United Republic of',
    'TH': 'Thailand',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad and Tobago',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TM': 'Turkmenistan',
    'TC': 'Turks and Caicos Islands',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom',
    'UM': 'United States Minor Outlying Islands',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela, Bolivarian Republic of',
    'VN': 'Viet Nam',
    'VG': 'Virgin Islands, British',
    'VI': 'Virgin Islands, U.S.',
    'WF': 'Wallis and Futuna',
    'EH': 'Western Sahara',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe'
};
var UNIVERSAL_FORM_SCENARIOS = {
    product: {
        'hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'contact-from-local-organogenesis-representative', label: 'Contact from a local Organogenesis Representative' },
                { value: 'product-information', label: 'Product Information' },
                { value: 'general-question', label: 'General Question' },
                { value: 'reimbursement-inquiry', label: 'Reimbursement Inquiry' },
                { value: 'product-order-delivery-support', label: 'Product Order / Delivery Support' },
                { value: 'patient-assistance-info', label: 'Patient Assistance Info' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'patient', label: 'Patient' },
                { value: 'regulatory', label: 'Regulatory' },
                { value: 'customer-service', label: 'Customer Service' },
            ],
            scenarios: {
                '': [],
                'contact-from-local-organogenesis-representative': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'orthopedic-surgeon', label: 'Orthopedic Surgeon' },
                                { value: 'spine-orthopedic-surgeon', label: 'Spine Orthopedic Surgeon' },
                                { value: 'neurosurgeon', label: 'Neurosurgeon' },
                                { value: 'pain physician', label: 'Pain Physician' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container', validators: ['required'] }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by my local Organogenesis Tissue Regeneration Specialist regarding Apligraf.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'product-information': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'general-question': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'discussion-with-organogenesis-representative', label: 'Discussion with Organogenesis Representative' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'reimbursement-inquiry': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'product-order-delivery-support': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient-assistance-info': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'customer-service': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
        'not-hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'product-information', label: 'Product Information' },
                { value: 'general-question', label: 'General Question' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'patient', label: 'Patient' },
                { value: 'regulatory', label: 'Regulatory' },
            ],
            scenarios: {
                '': [],
                'product-information': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['required'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'general-question': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        {
                            id: '#contact-specialty-container',
                            name: 'specialty',
                            variants: [
                                { value: '', label: '' },
                                { value: 'dermatologist', label: 'Dermatologist' },
                                { value: 'podiatrist', label: 'Podiatrist' },
                                { value: 'plastic-surgeon', label: 'Plastic Surgeon' },
                                { value: 'general-surgeon', label: 'General Surgeon' },
                                { value: 'vascular-surgeon', label: 'Vascular Surgeon' },
                                { value: 'gerontologist', label: 'Gerontologist' },
                                { value: 'primary-care-physician', label: 'Primary Care Physician' },
                                { value: 'internal-medicine', label: 'Internal Medicine' },
                                { value: 'nurse', label: 'Nurse' },
                                { value: 'hospitalist', label: 'Hospitalist' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-npi-container', name: 'npi' },
                        { id: '#contact-institution-affiliation-container', name: 'institution' },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['required'] },
                        {
                            id: '#contact-reason-for-contact-container',
                            name: 'contactReason',
                            variants: [
                                { value: '', label: '' },
                                { value: 'literature-request', label: 'Literature request' },
                                { value: 'product-samples', label: 'Product Samples' },
                                { value: 'other', label: 'Other' }
                            ],
                            validators: ['required']
                        },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
    },
    corporate: {
        'hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'reimbursement-inquiry', label: 'Reimbursement Inquiry' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'corporate', label: 'Corporate' },
                { value: 'investor', label: 'Investor' },
                { value: 'press', label: 'Press' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
                { value: 'customer-service', label: 'Customer Service' },
            ],
            scenarios: {
                '': [],
                'reimbursement-inquiry': {
                    fields: [
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                        { id: '#contact-preferred-method-of-contact-container', validators: ['required'] },
                        { id: '#contact-sign-up-for-email-options-container' }
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'corporate': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'investor': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'press': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis  to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'customer-service': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-institution-affiliation-container', name: 'institution', validators: ['required'] },
                        { id: '#contact-customer-account-number-container', name: 'customerAccountNumber' },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
        'not-hcp': {
            'area-of-interest': [
                { value: '', label: '' },
                { value: 'compliance', label: 'Compliance' },
                { value: 'corporate', label: 'Corporate' },
                { value: 'investor', label: 'Investor' },
                { value: 'press', label: 'Press' },
                { value: 'patient', label: 'Patient' },
                { value: 'medical', label: 'Medical' },
                { value: 'regulatory', label: 'Regulatory' },
            ],
            scenarios: {
                '': [],
                'compliance': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    disclaimers: [
                        '<div>General Compliance Helpline & Email <a href="tel:800-598-6936">800-598-6936</a>; <a href="mailto:compliance@organo.com">compliance@organo.com</a></div><br /><div>- <strong>Note</strong>: Inquiries sent to this email address will not be done so anonymously.</div><br /><div>Anonymous Compliance Helpline: <a href="tel:833-880-0007">833-880-0007</a>; <a href="mailto:reports@lighthouse-services.com">reports@lighthouse-services.com</a>; <a target="_blank" href="https://lighthouse-services.com/organo">www.lighthouse-services.com/organo</a></div>'
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'corporate': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'investor': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'press': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'patient': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I authorize Organogenesis and those working with or on behalf of Organogenesis to contact me via email, mail, or phone to conduct marketing activities, which includes, but is not limited to, providing me with educational and promotional materials, information on Organogenesis products and patient support services related to my treatment or health condition and/or to conduct market research activities, which includes contacting me to participate in various activities such as surveys, focus groups, or interviews which would be funded by Organogenesis. I understand that Organogenesis will not release my personally identifiable information to any party, except as provided in this authorization or as permitted by applicable law, without first obtaining my (or my authorized representative’s) separate written consent. I understand that I may revoke this authorization at any time by contacting Organogenesis at <a href="tel:+1 888 432 5232">+1 888 432 5232</a> or in writing at Organogenesis 85 Dan Road, Canton, MA 02021.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'medical': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
                'regulatory': {
                    fields: [
                        { id: '#contact-title-container', name: 'namePrefix', validators: ['required'] },
                        { id: '#contact-first-name-container', name: 'firstName', validators: ['required'] },
                        { id: '#contact-last-name-container', name: 'lastName', validators: ['required'] },
                        { id: '#contact-email-container', name: 'email', validators: ['email'] },
                        { id: '#contact-phone-container', name: 'phone', validators: ['phone'] },
                        { id: '#contact-country-container', name: 'country', validators: ['required'] },
                        { id: '#contact-zip-code-container', name: 'zip', validators: ['zip'] },
                    ],
                    agreements: [
                        { name: 'contactRequest', label: 'I would like to be contacted by Organogenesis.' },
                        { name: 'shareInfo', label: 'I also agree to allow Organogenesis and its agents to collect the information provided and to be contacted by Organogenesis and its agents in the future regarding other products and services.' },
                        { name: 'euEconomicArea', label: 'If You are a Resident of the European Economic Area', id: 'eu-economic-area' },
                        { name: 'personalDataUse', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal data for the purposes identified in the information I have provided in this form.', class: 'eu-economic-area' },
                        { name: 'marketingCommunications', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of sending me marketing communications.', class: 'eu-economic-area' },
                        { name: 'internalAnalysis', label: 'By checking this box and pressing "Submit", I consent to Organogenesis\' use of my personal information for purposes of internal analysis.', class: 'eu-economic-area' },
                        { name: 'transferData', label: 'By checking this box and pressing "Submit" I consent to the transfer of my personal data to Organogenesis for the uses I have consented to above. I understand that Organogenesis is located in the United States, and that the European Commission has not determined that the federal and state laws of the United States provide EEA residents with an adequate level of data protection due to the country\'s surveillance laws. I understand that transfers of personal data to the United States involve risk, and consent to such risk by choosing to send my personal data to Organogenesis.', class: 'eu-economic-area' },
                    ]
                },
            },
        },
    }
};

$(function() {
    $.each(['hide', 'show'], function (i, ev) {
        var el = $.fn[ev];
        $.fn[ev] = function () {
          this.trigger(ev);
          return el.apply(this, arguments);
        };
    });
});

if (!Array.prototype.find) {
    Array.prototype.find = function (callback) {
        for (var i = 0; i < this.length; i++) {
            var match = callback(this[i]);
            if (match) {
                return this[i];
            }
        }
    }
}
if (!Array.prototype.includes) {
    Array.prototype.includes = function (value) {
        return !!this.find(function(item) {
            return item === value;
        })
    }
}
if (!String.prototype.includes) {
    String.prototype.includes = function(search) {
        return this.indexOf(search) > -1;
    }
}
var isValidString = function(value) {
    return !!value && value.length > 0;
};
var isValidEmail = function(value) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};
var isValidPhone = function(value) {
    var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return !!value && value.length > 0 && re.test(value);
};
var isValidZip = function(value) {
    return !!value && value.length >= 5;
};
var ContactForm = function(selector) {
    this.errors = false;
    this.selector = selector;
    this.$container = this.elements.container(selector);
    this.$container.on('success', this.containerSuccess);
    this.$form = this.elements.form(this.$container);
    this.$form.submit(this.onFormSubmit.bind(this));
    this.$fields = this.elements.fields(this.$form, this);
    this.$fields.on('clear:errors', this.onClearFieldError);
    this.$submit = this.elements.submit(this.$form);
};
ContactForm.prototype.onFormSubmit = function(e) {
    e.preventDefault();
    this.validate();
    this.$submit.trigger('disable');
    if (this.errors) {
        this.onValidationError();
    } else {
        this.request(
            this.$form,
            this.onFormSuccess.bind(this),
            this.onFormError.bind(this));
    }
    return false;
};
ContactForm.prototype.onFormSuccess = function() {
    this.$submit.trigger('enable');
    this.$container.trigger('success');
    this.$fields.trigger('clear');
};
ContactForm.prototype.onFormError = function(res) {
    if (!!res && !!res.responseJSON)
        console.log(res.responseJSON.message);
    this.$submit.trigger('error');
    // console.log('--> trigger on form error');
    this.$form.removeClass('has-errors');
};
ContactForm.prototype.onValidationError = function() {
    this.$submit.trigger('enable');
    this.$form.addClass('has-errors');
};
ContactForm.prototype.validate = function() {
    this.errors = false;
    this.$fields.trigger('valid');
};
ContactForm.prototype.containerSuccess = function() {
    $(this).addClass('success');
    setTimeout(function() {
        $container.removeClass('success');
    }, 10000)
};
ContactForm.prototype.elements = {
    container: function(selector) {
        var $container = $(selector).eq(0);
        if (!$container) throw new Error('Selector ' + selector + ' not found.');
        return $container;
    },
    form: function($container) {
        var $form = $('form', $container).eq(0);
        if (!$form) throw new Error('Form not found.');
        return $form;
    },
    fields: function($form, form) {
        var $fields = $('.form-group', $form);
        $fields.on('valid', function() {
            var $container = $(this);
            $container.trigger('clear:errors');
            var $error = $('.error-holder', $container);
            var $icon = $('.error-icon', $container);
            var $input = $('input', $container);
            if (!$input.length) $input = $('select', $container);
            if (!$input.length) $input = $('textarea', $container);
            if (!$input.length) return;
            var value = $input.eq(0).val();
            var validators = $input.attr('data-validators');
            var isValid = true,
                message;
            if (validators && validators.indexOf('email') > -1) {
                isValid = isValidEmail(value);
                message = 'Invalid email';
            }
            if (validators && validators.indexOf('required') > -1) {
                isValid = isValidString(value);
                message = 'Required field';
            }
            if (!isValid) {
                $error.html(message);
                $container.addClass('error');
                form.errors = true;
                if (!$icon.length) {
                    $error.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                }
            }
        });
        $fields.on('clear', function() {
            $(this).trigger('clear:errors')
            var $input = $('input', this);
            if (!$input.length) $input = $('textarea', this);
            if (!$input.length) {
                $input = $('select', this);
                $input.parent().find('.component-select').trigger('set:default');
            }
            if ($input.attr('type') == 'radio' || $input.attr('type') == 'checkbox') {
                $input.prop('checked', false);
                if ($input.attr('type') === 'radio') {
                    $('> div:not(.form-group)', $form).removeClass('visible');
                }
            } else {
                $input.val('');
            }
        });
        $fields.on('hide', function() {
            $(this).trigger('clear');
        });
        return $fields;
    },
    submit: function($form) {
        var $submit = $('.form-submit', $form);
        var $button = $('button', $submit);
        $submit.on('disable', function() {
            $submit.addClass('disabled');
            $button.attr('disabled', true);
        });
        $submit.on('enable', function() {
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
        });
        $submit.on('error', function() {
            $submit.addClass('error');
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
            setTimeout(function() {
                $submit.removeClass('error');
            }, 10000);
        })
        return $submit;
    }
}
ContactForm.prototype.onClearFieldError = function() {
    var $container = $(this);
    var $error = $('> label span', $container);
    var $error = $('.error-holder', $container);
    if (!$error.length) {
        $error = $('<span></span>');
        $('> label', $container).append($error);
        $error = $('<span>').addClass('error-holder');
        $container.append($error);
    } else {
        $error.html('');
    }
    if ($container.hasClass('error')) {
        $container.removeClass('error');
    }
};
ContactForm.prototype.save = function($form) {
    var formData = this.serialize($form);
    var key = this.dataAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.dataUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.send = function($form) {
    var formData = this.serialize($form);
    delete formData['user-type']

    var key = this.emailAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.emailUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.serialize = function($form) {
    var formData = $form.serializeArray().reduce(function(data, field) {
        data[field.name] = field.value;
        return data;
    }, {});
    return formData;
}
ContactForm.prototype.request = function($form, success, error) {
    var self = this;
    this.save($form)
        .then(function() {
            self.send($form);
            success();
        }, error);
}

var FORM_TYPE = 'corporate';

jQuery(function() {
    $.widget('ogn.preferred-method-of-contact', {
        _create: function() {
            this.$phone = $('#preferred-method-phone-container', this.element);
            this.$email = $('#preferred-method-email-container', this.element);
        },
        _init: function() {
            $('input[name="preferred"]', this.element).on('change', this._change.bind(this));
        },
        _change: function(event) {
            var value = $(event.currentTarget).val();
            if (value === 'email') {
                this.$phone.hide();
                this.$email.show();
            } else {
                this.$phone.show();
                this.$email.hide();
            }
        }
    });
    $.widget('ogn.relationship-with-organogenesis', {
        _create: function () {
            this.$otherContainer = $('#relationship-other-container', this.element);
            this.$otherInput = $('input[type="text"]', this.$otherContainer);
        },
        _init: function () {
            $('input[name="relationshipWithOrganogenesis"]', this.element).on('change', this._change.bind(this));
        },
        _change: function (event) {
            var value = $(event.currentTarget).val();
            if (value === 'other') {
                this.$otherContainer.show();
            } else {
                this.$otherContainer.hide();
                this.$otherInput.val('');
            }
        }
    })
    $.widget('ogn.sign-up-for-email-options', {
        _create: function() {
            var related = this.element.data('related');
            this.$related = $(related);
            this.$email = $('#sign-up-email-container', this.element);
            this.relatedValue = 'email';
            this.isChecked = false;
            this.isHidden = true;
        },
        _init: function() {
            $('input[name="sign-up-for-email-options"]', this.element).on('change', this._onChangeCheckbox.bind(this));
            this.$related.on('change', this._onChangeRelated.bind(this));
            this.element.on('show', this._onShow.bind(this));
            this.element.on('hide', this._onHide.bind(this));
        },
        _onShow: function() {
            this.isHidden = false;
        },
        _onHide: function() {
            this.isHidden = true;
        },
        _onChangeCheckbox: function(event) {
            this.isChecked = $(event.currentTarget).prop('checked');
            this._toggleInput();
        },
        _onChangeRelated: function(event) {
            this.relatedValue = $(event.currentTarget).val();
            this._toggleInput();
        },
        _toggleInput: function() {
            if (this.isChecked && this.relatedValue !== 'email') {
                this.$email.show();
            } else {
                this.$email.hide();
            }
        }
    });
    $.widget('ogn.scenario-input', {
        _create: function() {
            this.id = this.element.attr('id');
            this.hashID = '#' + this.id;
            this.validators = [];

            this.isFormGroup = this.element.hasClass('form-group');
            this.isPreferredMethodOfContact = this.element.hasClass('preferred-method-of-contact');
            this.isSignupForEmailsOption = this.element.hasClass('sign-up-for-email-options');
            this.isRelationshipWithOrganogenesis = this.element.hasClass('relationship-with-organogenesis');
            this.isPrivacyRequestType = this.element.hasClass('privacy-request-type');
            this.isDoB = this.element.hasClass('birthday')
            if (this.isFormGroup) {
                this.$input = $('input, select, textarea, date', this.element);
                this.$errorHolder = $('<span>').addClass('error-holder').appendTo(this.element);
            }
            this.preferredMethod = 'email';
            if (this.isPreferredMethodOfContact) {
                this.$phoneHolder = $('#preferred-method-phone-container', this.element);
                this.$emailHolder = $('#preferred-method-email-container', this.element);
                this.$phoneInput = $('input[name="preferred-phone"]', this.element);
                this.$emailInput = $('input[name="preferred-email"]', this.element);
                this.$phoneErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$phoneHolder);
                this.$emailErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$emailHolder);
            }
            if (this.isSignupForEmailsOption) {
                this.isSignupChecked = false;
                this.$emailHolder = $('#sign-up-email-container', this.element);
                this.$emailInput = $('input[name="signup-email"]', this.element);
                this.$emailErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$emailHolder);
            }
            if (this.isRelationshipWithOrganogenesis) {
                this.relationshipValue = 'customer-patient';
                this.$otherHolder = $('#relationship-other-container', this.element);
                this.$otherInput = $('input[name="relationshipWithOrganogenesisOther"]', this.element);
                this.$otherErrorHolder = $('<span>').addClass('error-holder').appendTo(this.$otherHolder);
            }
            if (this.isPrivacyRequestType) {
                this.privacyRequestType = 'know-access'
            }
        },
        _init: function() {
            this.element.on('show', this._onShow.bind(this));
            this.element.on('hide', this._onHide.bind(this));
            if (this.isPreferredMethodOfContact || this.isSignupForEmailsOption || this.isRelationshipWithOrganogenesis || this.isPrivacyRequestType) {
                $('input[name="preferred"]').on('change', this._onChangePreferredRadio.bind(this));
                $('input[name="sign-up-for-email-options"]', this.element).on('change', this._onChangeSignupCheckbox.bind(this));
                $('input[name="relationshipWithOrganogenesis"]', this.element).on('change', this._onchangeRelationsWithOrganogenesisRadio.bind(this));
                $('input[name="requestType"]', this.element).on('change', this._onChangePrivacyRequestTypeRadio.bind(this));
            }
        },
        _onChangeSignupCheckbox: function(event) {
            if (this.isSignupForEmailsOption) {
                this.isSignupChecked = $(event.currentTarget).prop('checked');
            }
        },
        _onChangePreferredRadio: function(event) {
            if (this.isPreferredMethodOfContact || this.isSignupForEmailsOption) {
                this.preferredMethod = $(event.currentTarget).val();
            }
        },
        _onchangeRelationsWithOrganogenesisRadio: function(event) {
            if (this.isRelationshipWithOrganogenesis) {
                this.relationshipValue = $(event.currentTarget).val();
            }
        },
        _onChangePrivacyRequestTypeRadio: function(event) {
            if (this.isPrivacyRequestType) {
                this.privacyRequestType = $(event.currentTarget).val();
            }
        },
        _onShow: function(event) {
            event.preventDefault();
            if (this.element.get(0) !== event.target) return false;
            this._clearErrors();
        },
        _onHide: function(event) {
            event.preventDefault();
            if (this.element.get(0) !== event.target) return false;

            if (this.isFormGroup) {
                this.$input.val('');
                var tagName = this.$input.prop('tagName');
                if (tagName === 'SELECT') {
                    $('.component-select', this.element).trigger('set:default');
                }
            }
            if (this.isPreferredMethodOfContact) {
                $('input[name="preferred"][value="email"]').prop('checked', true);
                this.$phoneHolder.hide();
                this.$emailHolder.show();
                this.$phoneInput.val('');
                this.$emailInput.val('');
            }
            if (this.isSignupForEmailsOption) {
                $('input[name="sign-up-for-email-options"]', this.element).prop('checked', false);
                this.$emailHolder.hide();
                this.$emailInput.val('');
            }
            if (this.isRelationshipWithOrganogenesis) {
                $('input[name="relationshipWithOrganogenesis"][value="customer-patient"]').prop('checked', true).trigger('change');
                this.$otherHolder.hide();
                this.$otherInput.val('');
            }
            if (this.isPrivacyRequestType) {
                $('input[name="requestType"][value="know-access"]').prop('checked', true).trigger('change');
            }
            return false;
        },
        _clearErrors: function() {
            $('.error-icon', this.element).remove();
            if (this.$errorHolder) {
                this.$errorHolder.empty();
            }
            if (this.$phoneErrorHolder) {
                this.$phoneErrorHolder.empty();
            }
            if (this.$emailErrorHolder) {
                this.$emailErrorHolder.empty();
            }
            if (this.$phoneHolder) {
                this.$phoneHolder.removeClass('error');
            }
            if (this.$emailHolder) {
                this.$emailHolder.removeClass('error');
            }
            if (this.$otherErrorHolder) {
                this.$otherErrorHolder.empty();
            }
            if (this.$otherHolder) {
                this.$otherHolder.removeClass('error');
            }
            this.element.removeClass('error');
        },
        update: function(options) {
            if (options.validators) {
                this.validators = options.validators;
            }
            if (options.name) {
                this.serializeName = options.name;
            }
            if (this.isFormGroup) {
                var tagName = this.$input.prop('tagName');
                if (tagName === 'SELECT' && Array.isArray(options.variants)) {
                    var options = options.variants.map(function(item) {
                        return $('<option>').attr('value', item.value).html(item.label);
                    });
                    this.$input.empty().append(options).dropdownselect('rebuild');
                }
            }
        },
        validate: function() {
            var isValid = true, message = '';
            this._clearErrors();
            if (!this.validators.length) {
                return isValid;
            }
            if (this.isFormGroup) {
                var value = this.$input.val();
                if (this.validators.indexOf('required') > -1) {
                    isValid = isValidString(value);
                    message = 'Required field';
                }
                if (this.validators.indexOf('email') > -1) {
                    isValid = isValidEmail(value);
                    message = 'Email is invalid';
                }
                if (this.validators.indexOf('phone') > -1) {
                    isValid = isValidPhone(value);
                    message = 'Phone is invalid';
                }
                if (this.validators.indexOf('zip') > -1) {
                    isValid = isValidZip(value);
                    message = 'ZIP/Postal code is invalid';
                }
                if (!isValid) {
                    this.element.addClass('error');
                    this.$errorHolder.html(message)
                    if (!this.element.has('i').length) {
                        this.$errorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                    }
                    return isValid;
                }

            }
            if (this.isPreferredMethodOfContact) {
                var phoneValue = this.$phoneInput.val();
                var emailValue = this.$emailInput.val();
                if (this.validators.indexOf('required') > -1 && this.preferredMethod === 'phone') {
                    isValid = isValidPhone(phoneValue);
                    message = 'Phone is invalid';
                    if (!isValid) {
                        this.$phoneHolder.addClass('error');
                        this.$phoneErrorHolder.html(message)
                        if (!this.$phoneHolder.has('i').length) {
                            this.$phoneErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                        }
                        return isValid;
                    }
                }
                if (this.validators.indexOf('required') > -1 && this.preferredMethod === 'email') {
                    isValid = isValidEmail(emailValue);
                    message = 'Email is invalid';
                    if (!isValid) {
                        this.$emailHolder.addClass('error');
                        this.$emailErrorHolder.html(message)
                        if (!this.$emailHolder.has('i').length) {
                            this.$emailErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                        }
                        return isValid;
                    }
                }
            }
            if (this.isSignupForEmailsOption) {
                var emailValue = this.$emailInput.val();
                if (this.validators.indexOf('required') > -1 && this.preferredMethod !== 'email' && this.isSignupChecked) {
                    isValid = isValidEmail(emailValue);
                    message = 'Email is invalid';
                    if (!isValid) {
                        this.$emailHolder.addClass('error');
                        this.$emailErrorHolder.html(message)
                        if (!this.$emailHolder.has('i').length) {
                            this.$emailErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                        }
                        return isValid;
                    }
                }
            }
            if (this.isRelationshipWithOrganogenesis) {
                var relationshipOtherValue = this.$otherInput.val();
                if (this.relationshipValue == 'other' && relationshipOtherValue.length < 1) {
                    this.$otherHolder.addClass('error');
                    this.$otherErrorHolder.html('This value should be provided');
                    if (!this.$otherHolder.has('i').length) {
                        this.$otherErrorHolder.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                    }
                    return false;
                }
            }
            return isValid;
        },
        serialize: function() {
            var response = {};
            if (this.isDoB) {
                var date = new Date(this.$input.get(0).valueAsNumber);
                response['dob'] = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
                return response;
            }
            if (this.isFormGroup) {
                var value = this.$input.val();
                response[this.serializeName] = value;
                return response;
            }
            if (this.isPreferredMethodOfContact) {
                var phoneValue = this.$phoneInput.val();
                var emailValue = this.$emailInput.val();
                if (this.preferredMethod === 'phone') {
                    return { 'preferredMethod': 'phone', phone: phoneValue };
                }
                if (this.preferredMethod === 'email') {
                    return { 'preferredMethod': 'email', email: emailValue };
                }
            }
            if (this.isSignupForEmailsOption) {
                if (!this.isSignupChecked) {
                    return { 'signupForEmails': false };
                }
                var emailValue = this.$emailInput.val();
                if (this.preferredMethod !== 'email' && this.isSignupChecked) {
                    return { 'signupForEmails': true, email: emailValue };
                } else if (this.preferredMethod === 'email' && this.isSignupChecked) {
                    return { 'signupForEmails': true };
                }
            }
            if (this.isRelationshipWithOrganogenesis) {
                var inputValue = this.$otherInput.val();
                if (this.relationshipValue === 'other') {
                    return { relationshipWithOrganogenesis: this.relationshipValue, relationshipWithOrganogenesisOther: inputValue };
                } else {
                    return { relationshipWithOrganogenesis: this.relationshipValue };
                }
            }
            if (this.isPrivacyRequestType) {
                return { requestType: this.privacyRequestType };
            }
            return response;
        },
        show: function() {
            this.element.show();
        },
    });
    if ($('#get-in-contact-form').length) {
        $('.form-select').dropdownselect();
        $('#contact-preferred-method-of-contact-container')['preferred-method-of-contact']();
        $('#contact-sign-up-for-email-options-container')['sign-up-for-email-options']();
        var GetInContactForm = function(selector) {
            ContactForm.call(this, selector);
            var hostname = window.location.hostname;
            this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.dataAPIKey = 'PsAxqib6hr3anKOn3eouHHw7Hi3yFyrK';
            this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.emailAPIKey = 'DShuQTBjQjJrYeX5DsX83cjX3BzTXQTNB';

            if (/^organogenesis\.com$/.test(hostname) || /^www\.organogenesis\.com$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.com/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.com/webform_rest/submit';
            } else if (/^organogenesis\.dev$/.test(hostname) || /^www\.organogenesis\.dev$/.test(hostname)) {
                this.dataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
                this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            } else if (/^sfsk-organogenesis-gulp\.lndo\.site$/.test(hostname)) {
                this.dataUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
                this.emailUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
            }

            this.$fields = $('.scenario-container')['scenario-input']();
            var scenarioFields = {};
            $.each(this.$fields, function(i, field) {
                var widget = $(field)['scenario-input']().data('ognScenarioInput');
                scenarioFields[widget.hashID] = widget;
            });
            this.scenarioFields = scenarioFields;
            this.scenario = [];
            this.agreements = [];

            this.formType = FORM_TYPE;
            this.iAmValue = '';
            this.$areaOfIntrestSelect = $('#contact-area-of-interest-input', selector);
            this.$areaOfIntrestSelect.on('change', this.onChangeAreaOfInterest.bind(this));
            this.$userTypeRadio = $('input[name="userType"]', selector);
            this.$userTypeRadio.on('change', this.onChangeUserType.bind(this));
        };
        GetInContactForm.prototype = Object.create(ContactForm.prototype);
        GetInContactForm.prototype.constructor = GetInContactForm;
        GetInContactForm.prototype.onChangeUserType = function(event) {
            event.preventDefault();
            this.beforeChangeScenario();
            var $current = $(event.currentTarget);
            var $form = $('.form-holder', this.selector);
            if ($current.is(':checked')) {
                this.iAmValue = $current.val();
                var interestsOptions = UNIVERSAL_FORM_SCENARIOS[FORM_TYPE][this.iAmValue]['area-of-interest'].map(function(item) {
                    return $('<option>').attr('value', item.value).html(item.label);
                });
                this.$areaOfIntrestSelect.html('').append(interestsOptions);

                this.$areaOfIntrestSelect.dropdownselect('rebuild');
                $form.addClass('visible');
            } else {
                $form.removeClass('visible');
            }
        }
        GetInContactForm.prototype.onChangeAreaOfInterest = function(event) {
            event.preventDefault();
            this.beforeChangeScenario();
            var value = $(event.currentTarget).val();
            var scenario = UNIVERSAL_FORM_SCENARIOS[FORM_TYPE][this.iAmValue]['scenarios'][value];
            if (!scenario) {
                scenario = { fields: [], agreements: [] };
            }
            scenario['fields'].forEach(this.showScenarioField.bind(this));
            scenario['agreements'].forEach(this.insertAgreement.bind(this));
            this.$euEconomicArea = $('.eu-economic-area');
            this.$euEconomicAreaCheckboxes = $('.eu-economic-area input[type="checkbox"]');
            this.$generalAgreementCheckboxes = $('.scenario-agreement:not(.eu-economic-area):not(#eu-economic-area) input[type="checkbox"]');
            this.$euEconomicArea.on('hide', this.onHideEUEconomicArea.bind(this));
            this.$euEconomicAreaToggler = $('#eu-economic-area input[type="checkbox"]');
            this.$euEconomicAreaToggler.on('change', this.onChangeEUEconomicArea.bind(this));
            $('.scenario-agreement input[type="checkbox"]').on('change', this.onChangeAgreementValue.bind(this));

            if (scenario['disclaimers']) {
                scenario['disclaimers'].forEach(this.insertDisclaimers.bind(this));
            }

            $('.form-submit').show();
            $('#contact-disclaimer').show();
        }
        GetInContactForm.prototype.onChangeAgreementValue = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            var $button = $('#universal-contact-submit');
            var isButtonDisabled = false;
            isButtonDisabled = this.$generalAgreementCheckboxes.map(function() {
                return $(this).is(':checked');
            }).get().some(function(isChecked) {
                return !isChecked;
            });
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            if (this.$euEconomicAreaToggler.is(':checked')) {
                isButtonDisabled = this.$euEconomicAreaCheckboxes.map(function() {
                    return $(this).is(':checked');
                }).get().some(function(isChecked) {
                    return !isChecked;
                });
            }
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            $button.prop('disabled', false);
        }
        GetInContactForm.prototype.onHideEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            $('input', event.target).prop('checked', false);
        }
        GetInContactForm.prototype.onChangeEUEconomicArea = function(event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            if ($(event.target).is(':checked')) {
                this.$euEconomicArea.show();
            } else {
                this.$euEconomicArea.hide();
            }
        };
        GetInContactForm.prototype.beforeChangeScenario = function() {
            this.errors = false;
            this.$form.removeClass('has-errors');
            this.$fields.hide();
            this.scenario = [];
            this.agreements = [];
            $('.scenario-disclaimer').remove();
            $('.scenario-agreement').remove();
            $('.form-submit').hide();
            $('#contact-disclaimer').hide();
            $('#universal-contact-submit').prop('disabled', true);
        }
        GetInContactForm.prototype.showScenarioField = function(options) {
            var $field = this.scenarioFields[options.id];
            if ($field) {
                this.scenario.push($field);
                $field.update(options);
                $field.show();
            }
        }
        GetInContactForm.prototype.insertDisclaimers = function(disclaimer, index) {
            var id = 'contact-scenario-disclaimer-'  + index;
            if ($('#' + id, this.selector).length) return;
            $('<p>').attr('id', id).addClass('scenario-disclaimer').html(disclaimer).insertAfter('#contact-disclaimer');
        }
        GetInContactForm.prototype.insertAgreement = function(agreement, index, agreements) {
            if ($('#' + agreement.name, this.selector).length) return;
            var $agreementContainer = $('<div>').addClass('form-group form-check custom-checkbox scenario-agreement');
            if (agreement.class) {
                $agreementContainer.addClass(agreement.class);
            }
            if (agreement.id) {
                $agreementContainer.attr('id', agreement.id);
            }
            if (agreement.class && agreement.class === 'eu-economic-area') {
                $agreementContainer.css('display', 'none');
            }
            var $agreement = $('<input>')
                .attr('name', agreement.name)
                .attr('type', 'checkbox')
                .attr('id', agreement.name)
                .addClass('form-check-input mt-0')
                .appendTo($agreementContainer);
            this.agreements.push($agreement);
            $('<label>')
                .attr('for', agreement.name)
                .addClass('form-check-label white')
                .append(agreement.label)
                .appendTo($agreementContainer);
            $agreementContainer.appendTo('#contact-confirmation-checkboxes');
        }

        GetInContactForm.prototype.serialize = function() {
            var formData = this.scenario.reduce(this.onFieldSerialize.bind(this), {});
            var origin = window.location.href;
            if (origin.indexOf('#') > -1) {
                origin = origin.substring(0, origin.indexOf('#'));
            }
            formData.origin = origin;
            // Provide the required parameter webform_id for the correct form submission.
            formData.webform_id = 'organogenesis_contact_form';
            var userTypes = {
                'hcp': 'US HCP',
                'not-hcp': 'Not US HCP'
            };
            formData['userType'] = userTypes[this.iAmValue];
            // Extract area of interest
            var areaOfInterestCode = this.$areaOfIntrestSelect.val();
            var scenario = UNIVERSAL_FORM_SCENARIOS[FORM_TYPE][this.iAmValue]['scenarios'][areaOfInterestCode];
            var areaOfInterest = UNIVERSAL_FORM_SCENARIOS[FORM_TYPE][this.iAmValue]['area-of-interest'].find(function(element) {
                return element.value === areaOfInterestCode;
            })
            formData['areaOfInterest'] = areaOfInterest ? areaOfInterest['label'] : areaOfInterestCode;
            // Extract specialty
            var specialties = scenario.fields.find(function(element) {
                return element.name === 'specialty'
            });
            if (formData['specialty'] && specialties) {
                var specialty = specialties.variants.find(function(element) {
                    return element.value === formData['specialty'];
                });
                formData['specialty'] = specialty ? specialty.label : formData['specialty'];
            }
            // Extract Reason for contact
            var contactReasons = scenario.fields.find(function(element) {
                return element.name === 'contactReason';
            });
            if (formData['contactReason'] && contactReasons) {
                var contactReason = contactReasons.variants.find(function(element) {
                    return element.value === formData['contactReason'];
                });
                formData['contactReason'] = contactReason ? contactReason.label : formData['contactReason'];
            }

            var agreements = this.$generalAgreementCheckboxes.get().reduce(function(results, checkbox) {
                var $checkbox = $(checkbox);
                results[$checkbox.attr('name')] = $checkbox.is(':checked');
                return results;
            }, {});
            agreements[this.$euEconomicAreaToggler.attr('name')] = this.$euEconomicAreaToggler.is(':checked');
            if (this.$euEconomicAreaToggler.is(':checked')) {
                agreements = this.$euEconomicAreaCheckboxes.get().reduce(function(results, checkbox) {
                    var $checkbox = $(checkbox);
                    results[$checkbox.attr('name')] = $checkbox.is(':checked');
                    return results;
                }, agreements);
            }
            formData = Object.assign(formData, agreements);
            return formData;
        };
        GetInContactForm.prototype.onFieldSerialize = function(formData, $field) {
            var data = $field.serialize();
            return Object.assign(formData, data);
        }
        GetInContactForm.prototype.request = function($form, success, error) {
            this.save($form).then(success, error);
        };
        GetInContactForm.prototype.containerSuccess = function() {
            var container = $(this);
            container.addClass('success');
            $('html,body').animate({ scrollTop: container.offset().top }, 'slow');

            var $userTypeRadio = $('#get-in-contact-form input[name="userType"]');
            $userTypeRadio.removeAttr('checked').trigger('change');
            $('#get-in-contact-form form').trigger('reset');
            setTimeout(function() {
                container.removeClass('success');
            }, 10000)
        }
        GetInContactForm.prototype.validate = function() {
            this.errors = false;
            this.scenario.forEach(this.onFieldValidate.bind(this))
        }
        GetInContactForm.prototype.onFieldValidate = function($field) {
            var isInvalid = !$field.validate();
            if (isInvalid) {
                this.errors = true;
            }
        }
        GetInContactForm.prototype.onValidationError = function() {
            ContactForm.prototype.onValidationError.call(this);
            $('html,body').animate({ scrollTop: $('form', this.selector).offset().top }, 'slow');
        }

        new GetInContactForm('#get-in-contact-form');
    }
    if ($('#privacy-request-form').length) {
        $('#privacy-request-relationship-container')['relationship-with-organogenesis']();
        var PrivacyRequestForm = function (selector) {
            ContactForm.call(this, selector);
            var hostname = window.location.hostname;
            // The CCPA-CPRA Consumer Request Form.
            this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            this.emailAPIKey = 'DShuQTBjQjJrYeX5DsX83cjX3BzTXQTNB';

            if (/^organogenesis\.com$/.test(hostname) || /^www\.organogenesis\.com$/.test(hostname)) {
                this.emailUrl = 'https://leads.organogenesis.com/webform_rest/submit';
            } else if (/^organogenesis\.dev$/.test(hostname) || /^www\.organogenesis\.dev$/.test(hostname)) {
                this.emailUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
            } else if (/^sfsk-organogenesis-gulp\.lndo\.site$/.test(hostname)) {
                this.emailUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
            }

            this.$fields = $('.scenario-container')['scenario-input']();

            var scenarioFields = [];
            var options = {
                'privacy-request-name-container': {
                    name: 'name',
                    validators: ['required']
                },
                'privacy-request-birthday-container': {
                    name: 'dob',
                    validators: ['required', 'dob']
                },
                'privacy-request-email-container': {
                    name: 'email',
                    validators: ['email']
                },
                'privacy-request-mailing-address-container': {
                    name: 'mailingAddress',
                    validators: ['required']
                },
                'privacy-request-phone-container': {
                    name: 'phone',
                    validators: ['phone']
                },
                'privacy-request-relationship-container': {
                    name: 'relationshipWithOrganogenesis',
                    validators: ['required']
                },
                'privacy-request-type-container': {
                    name: 'requestType'
                },
                'privacy-request-additional-details-container': {
                    name: 'additionalRequestDetails'
                }
            }
            $.each(this.$fields, function (i, field) {
                var $field = $(field);
                var id = $field.attr('id');
                var fieldOptions = options[id];
                var widget = $field['scenario-input']().data('ognScenarioInput');
                if (fieldOptions) {
                    widget.update(fieldOptions);
                }
                scenarioFields.push(widget);
            });
            this.scenario = scenarioFields;
            $('#agreement').on('change', this.onChangeAgreementValue.bind(this));
        };
        PrivacyRequestForm.prototype = Object.create(ContactForm.prototype);
        PrivacyRequestForm.prototype.constructor = PrivacyRequestForm;

        PrivacyRequestForm.prototype.onChangeAgreementValue = function (event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            var $button = $('#privacy-request-submit');
            var isButtonDisabled = !$(event.target).is(':checked');
            if (isButtonDisabled) {
                $button.prop('disabled', true);
                return;
            }
            $button.prop('disabled', false);
        };
        PrivacyRequestForm.prototype.validate = function () {
            this.errors = false;
            this.scenario.forEach(this.onFieldValidate.bind(this))
        };
        PrivacyRequestForm.prototype.onFieldValidate = function ($field) {
            var isInvalid = !$field.validate();
            if (isInvalid) {
                this.errors = true;
            }
        };
        PrivacyRequestForm.prototype.onValidationError = function () {
            ContactForm.prototype.onValidationError.call(this);
            $('html,body').animate({
                scrollTop: $('form', this.selector).offset().top
            }, 'slow');
        };
        PrivacyRequestForm.prototype.serialize = function () {
            var formData = this.scenario.reduce(this.onFieldSerialize.bind(this), {});
            // var origin = window.location.href;
            // if (origin.indexOf('#') > -1) {
            //     origin = origin.substring(0, origin.indexOf('#'));
            // }
            // formData.origin = origin;
            // Provide the required parameter webform_id for the correct form submission.
            formData.webform_id = 'organogenesis_privacy_request';
            formData.senderId = 'ognPrivacyRequest';
            // formData.dob = new Date(formData.dob).toISOString(); // Convert to ISO 8601 format
            formData.agreePrivacyRequestRules = true;
            formData.authorizedAgent = $('input[name="authorized-agent"]', this.element).is(':checked');
            return formData;
        };
        PrivacyRequestForm.prototype.onFieldSerialize = function (formData, $field) {
            var data = $field.serialize();
            return Object.assign(formData, data);
        };
        PrivacyRequestForm.prototype.request = function ($form, success, error) {
            var scenario = this.scenario;
            var handleSubmit = function () {
                success();
                // scenario.forEach(function ($field) {
                //     $field.trigger('hide');
                // })
            }
            this.send($form).then(handleSubmit, error);
        };
        PrivacyRequestForm.prototype.containerSuccess = function () {
            var container = $(this);
            container.addClass('success');
            $('html,body').animate({
                scrollTop: container.offset().top
            }, 'slow');
            $('input[name="authorized-agent"]').prop('checked', false);
            $('input[name="agreement"]').prop('checked', false);
            $('#privacy-request-submit').prop('disabled', true);
            $('#privacy-request-form form').trigger('reset');
            setTimeout(function () {
                container.removeClass('success');
            }, 10000)
        };

        new PrivacyRequestForm('#privacy-request-form');
    }
    $.widget('ogn.form-contact-ssm', {
        _create: function() {
            $('button', this.element).on('click', this._onClick);
        },
        _onClick: function() {
            window.location = "mailto:surgicalmarketing@organo.com?subject=Contact Us: Surgical and Sports Medicine";
        }
    });
    var ssmEmailContact = $('.component-form-contact-ssm-email');
    if ($('.component-form-contact-ssm-email').length) {
        ssmEmailContact['form-contact-ssm']();
    }
});
